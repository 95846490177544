











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab2Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        recrystallizationPurpose: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'It is a good way to concentrate the product', value: 'concentrateProduct'},
        {text: 'It is a good way to isolate the product', value: 'isolateProduct'},
        {text: 'It is a purification process', value: 'purifyProduct'},
        {text: 'It is an easy way to isolate and purify the product', value: 'isolateAndPurify'},
      ],
      optionsFr: [
        {
          text: 'Elle est une bonne manière de concentrer le produit',
          value: 'concentrateProductFr',
        },
        {text: 'Elle est une bonne manière d’isoler le produit', value: 'isolateProductFr'},
        {text: 'Elle est un processus de purification', value: 'purifyProductFr'},
        {
          text: 'Elle est une façon facile d’isoler et purifier le produit',
          value: 'isolateAndPurify',
        },
      ],
    };
  },
};
